import React from 'react'
import { Link } from 'gatsby'
import VisibilitySensor from 'react-visibility-sensor'
import { StaticImage } from 'gatsby-plugin-image'
import { CORPORATE_SITE } from '../../../utils'

export function CompareSlides() {
    return (
        <section id="compare-slides" className="mb-5 background hero">
            <div className="container">
                <div className="row mx-2 mx-xl-5">
                    <h1 className="text-center milan-text-primary">
                        Laser Hair Removal vs. Everything Else
                    </h1>
                    <p className="text-center mt-4 mb-0">
                        Laser Hair Removal is the best solution to ridding
                        yourself of unwanted body hair. Other ways of managing
                        your unwanted hair – like waxing or at-home devices –
                        are more painful, less effective and more time consuming
                        than laser hair removal.
                    </p>
                </div>
                <div className="row mx-2 mx-xl-5 mt-3 mt-lg-5 align-items-center white-bg">
                    <div className="col-lg-5 text-center px-0 side-img">
                        <StaticImage
                            src="../../../images/process/compare/compare-laser.webp"
                            alt="Laser Hair Removal: Cost, Pain, and Skin Types"
                            imgClassName="mx-auto mw-100"
                        />
                    </div>
                    <div className="col-lg-7">
                        <VisibilitySensor
                            partialVisibility
                            offset={{ bottom: 50 }}
                        >
                            {({ isVisible }) => (
                                <div
                                    className={`anchor-blue text-center ms-lg-4 mt-3 mt-md-0 p-sm-4 paragraph ${
                                        isVisible ? 'slide-right' : ''
                                    }`}
                                >
                                    <p>
                                        On average, Milan Laser clients see up
                                        to 95% hair reduction in treated areas
                                        in 7-10 treatments. Our lasers can
                                        safely remove hair on all skin tones.
                                        Laser hair removal provides you with
                                        better (and permanent) results in less
                                        time &amp; with less pain than at-home
                                        devices.
                                    </p>
                                    <p>
                                        Additionally, you do not have to let the
                                        hair grow out in order for laser hair
                                        removal to be successful. In fact, it's
                                        required that the area be clean shaven
                                        on the day of your treatment.
                                    </p>
                                    <p className="mb-sm-0">
                                        <Link
                                            to="/process/"
                                            className="milan-text-primary"
                                        >
                                            Find out how laser hair removal
                                            permanently removes your hair.
                                        </Link>
                                    </p>
                                </div>
                            )}
                        </VisibilitySensor>
                    </div>
                </div>
                <div className="row mx-2 mx-xl-5 mt-3 mt-lg-5 align-items-center white-bg flex-lg-row-reverse">
                    <div className="col-lg-5 text-center px-0 side-img">
                        <StaticImage
                            src="../../../images/process/compare/compare-ipl.webp"
                            alt="IPL Intense Pulsed Light"
                            imgClassName="mx-auto mw-100"
                        />
                    </div>
                    <div className="col-lg-7">
                        <VisibilitySensor
                            partialVisibility
                            offset={{ bottom: 50 }}
                        >
                            {({ isVisible }) => (
                                <div
                                    className={`anchor-blue text-center me-lg-4 mt-3 mt-md-0 p-sm-4 paragraph ${
                                        isVisible ? 'slide-left' : ''
                                    }`}
                                >
                                    <p>
                                        Most at-home devices – as well as a lot
                                        of med spas – use IPL technology to
                                        remove hair. IPL is multi-wavelength,
                                        scattered light that is generated from
                                        flash lamps &amp; is not “true laser”
                                        light because it spreads out instead of
                                        staying in a targeted beam. When you
                                        compare IPL to our lasers (because IPL
                                        isn’t a laser!) it’s a no brainer that
                                        laser hair removal is the better
                                        solution to your unwanted hair.
                                    </p>
                                    <p className="mb-sm-0">
                                        At best, IPL users see an 80% reduction
                                        in unwanted hair over the course of 10+
                                        treatments. In addition to producing
                                        inferior results, these devices only
                                        work on light to medium skin tones –
                                        they severely burn darker skin tones.
                                        IPL devices (whether at-home or at a med
                                        spa) are more painful than our lasers,
                                        and require a lot more time to maintain
                                        results.
                                    </p>
                                </div>
                            )}
                        </VisibilitySensor>
                    </div>
                </div>
                <div className="row mx-2 mx-xl-5 mt-3 mt-lg-5 align-items-center white-bg">
                    <div className="col-lg-5 text-center px-0 side-img">
                        <StaticImage
                            src="../../../images/process/compare/compare-shaving.webp"
                            alt="Shaving: Cost, Pain, and Skin Types"
                            imgClassName="mx-auto mw-100"
                        />
                    </div>
                    <div className="col-lg-7">
                        <VisibilitySensor
                            partialVisibility
                            offset={{ bottom: 50 }}
                        >
                            {({ isVisible }) => (
                                <div
                                    className={`anchor-blue text-center ms-lg-4 mt-3 mt-md-0 p-sm-4 paragraph ${
                                        isVisible ? 'slide-right' : ''
                                    }`}
                                >
                                    <p>
                                        Comparing laser hair removal to shaving
                                        is like comparing day to night (or that
                                        dreaded 5 o’clock shadow!). This method
                                        of hair removal only provides you with
                                        temporary results with the average
                                        person shaving 100 times per year.
                                    </p>
                                    <p>
                                        If that wasn’t bad enough, it takes up
                                        an estimated 39 hours of your life each
                                        year – what a waste of time! While this
                                        method can safely be performed on all
                                        skin tones, it costs an estimated $1,300
                                        more in your lifetime than laser hair
                                        removal. On top of that, you have to let
                                        the hair grow out before you can shave
                                        it off…with laser hair removal there’s
                                        no need for embarrassing stubble.
                                    </p>
                                    <p className="mb-sm-0">
                                        <Link
                                            to="/areas/"
                                            className="milan-text-primary"
                                        >
                                            Discover all the areas that you
                                            could stop shaving – saving you time
                                            and money!
                                        </Link>
                                    </p>
                                </div>
                            )}
                        </VisibilitySensor>
                    </div>
                </div>
                <div className="row mx-2 mx-xl-5 mt-3 mt-lg-5 align-items-center white-bg flex-lg-row-reverse">
                    <div className="col-lg-5 text-center px-0 side-img">
                        <StaticImage
                            src="../../../images/process/compare/compare-waxing.webp"
                            alt="Waxing: Cost, Pain, and Skin Types"
                            imgClassName="mx-auto mw-100"
                        />
                    </div>
                    <div className="col-lg-7">
                        <VisibilitySensor
                            partialVisibility
                            offset={{ bottom: 50 }}
                        >
                            {({ isVisible }) => (
                                <div
                                    className={`anchor-blue text-center me-lg-4 mt-3 mt-md-0 p-sm-4 paragraph ${
                                        isVisible ? 'slide-left' : ''
                                    }`}
                                >
                                    <p>
                                        When comparing laser hair removal to
                                        waxing, it’s important to note that
                                        waxing is significantly more painful
                                        than removing body hair with a laser.
                                        Waxing does work on all skin tones – but
                                        costs nearly 10 times as much in your
                                        lifetime than laser hair treatments.
                                        Additionally, you’ll also need to devote
                                        8 hours of your life each year to going
                                        to 10 or more waxing appointments to
                                        maintain results.
                                    </p>
                                    <p>
                                        As if stealing your precious time, being
                                        more painful and more expensive wasn’t
                                        enough… you also have to let
                                        embarrassing stubble grow before each
                                        appointment in order for it to be
                                        effective!
                                    </p>
                                    <p className="mb-sm-0">
                                        <a
                                            href={`${CORPORATE_SITE}/faqs/waxing-vs-laser.html?utm_source=Milan+Mail&utm_campaign=c4165ee55d-Feb19-MID-MONTH-EMAIL&utm_medium=email&utm_term=0_253829b9cf-c4165ee55d-`}
                                            target="_blank"
                                            className="milan-text-primary"
                                        >
                                            Learn more about the drawbacks of
                                            waxing and why laser hair removal is
                                            your best solution to unwanted hair.
                                        </a>
                                    </p>
                                </div>
                            )}
                        </VisibilitySensor>
                    </div>
                </div>
                <div className="d-flex justify-content-around align-items-center flex-column flex-md-row mt-3 mt-lg-5">
                    <div>
                        <Link
                            className="milan-btn milan-primary-btn mb-3"
                            to="/process/beforeafter/"
                        >
                            Before &amp; After
                        </Link>
                    </div>
                    <div>
                        <Link
                            className="milan-btn milan-primary-btn mb-3"
                            to="/process/"
                        >
                            Our Technology
                        </Link>
                    </div>
                    <div>
                        <Link
                            className="milan-btn milan-primary-btn mb-3"
                            to="/specials/"
                        >
                            Current Specials
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default CompareSlides
