import { builder } from '@builder.io/sdk'
import builderConfig from '../../../builder-config'

const apiKey = builderConfig?.builderAPIKey

builder.init(apiKey) // Initialize Builder with your API key

export const getBuilderAPI = (model, apiKey, slug) => {
    switch (model) {
        //add more models here, if needed
        case 'landing-page':
            return {
                fetchUrl: `https://cdn.builder.io/api/v3/content/${model}?apiKey=${apiKey}&query.data.pageUrl=${slug}&cachebust=true`,
            }
        default:
            return {
                fetchUrl: `https://cdn.builder.io/api/v3/content/${model}?apiKey=${apiKey}&url=${slug}&cachebust=true`,
            }
    }
}

export const getBuilderData = async builderModelName => {
    const content = await builder
        // Get the page content from Builder with the specified options
        .get(builderModelName, {
            apiKey: process.env.BUILDER_API_KEY,
        })
        // Convert the result to a promise
        .toPromise()
    return content
}
