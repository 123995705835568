import React, { useContext, useState } from 'react';
import { LPUnlimitedPackage } from '../../../components/lp/LPUnlimitedPackage';
import { SignUpModal } from '../../../components/shared/SignUpModal';
import { GlobalSiteDataContext } from '../../../context';

export default function ULPackage2Builder() {
    const [showSignUpModal, setShowSignUpModal] = useState(false);
    const siteData = useContext(GlobalSiteDataContext);

    return (
        <div id="social-page">
            {showSignUpModal && <SignUpModal {...{ siteData, showSignUpModal, setShowSignUpModal }} />}
            <LPUnlimitedPackage setShowSignUpModal={setShowSignUpModal} />
        </div>
    );
}
