import React from 'react'

export function DarkSkinHero() {
    return (
        <section className="hero pt-milan-50 pb-0 py-md-milan-50 py-xl-milan-150 milan-bg-white">
            <div className="container">
                <div className="row">
                    {/* <div className="offset-md-6 col-md-6 offset-lg-5 col-lg-7"> */}
                    <div className="offset-md-6 col-md-6">
                        <h1 className="milan-text-primary">
                            Laser Hair Removal For Dark Skin Tones
                        </h1>
                        <p>
                            It's no longer true that laser hair removal only
                            works for certain skin tones. Here's the truth about
                            laser hair removal for dark skin tones.
                        </p>
                        <div className="d-none d-sm-block">
                            <p>Laser hair removal&#8230;</p>
                            <ul className="list-style-disc">
                                <li>
                                    is safe and effective for{' '}
                                    <strong>all skin tones.</strong>
                                </li>
                                <li>
                                    gets you{' '}
                                    <strong>
                                        up to 95% hair-free in 7 to 10 sessions.
                                    </strong>
                                </li>
                                <li>
                                    eliminates{' '}
                                    <strong>shaving side-effects</strong> like
                                    folliculitis, ingrown hairs, razor burn, and
                                    cuts.
                                </li>
                                <li>
                                    <strong>costs less</strong> than a lifetime
                                    of shaving and waxing.
                                </li>
                            </ul>
                        </div>
                        <p className="mb-0">
                            Say goodbye to unwanted hair. Permanently.
                        </p>
                    </div>
                </div>
            </div>
            {/* bg when stacked*/}
            <div className="d-md-none stack-hero-bg" />
        </section>
    )
}

export default DarkSkinHero
