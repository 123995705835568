// using module.exports here so gatsby-config can read it
const isShowBuilderPages = process.env.GATSBY_BUILDER_SHOW_PAGES === 'true';
const isShowBuilderLandingPages = process.env.GATSBY_BUILDER_SHOW_LANDING_PAGES === 'true';
const isShowBuilderSymbols = process.env.GATSBY_BUILDER_SHOW_SYMBOLS === 'true';
module.exports = {
    // TODO: Replace next line with your own public API key
    builderAPIKey: process.env.GATSBY_BUILDER_IO_API_KEY,
    //   showBuilderPages: process.env.GATSBY_BUILDER_SHOW_PAGES,
    showBuilderPages: isShowBuilderPages,
    showBuilderLandingPages: isShowBuilderLandingPages,
    showBuilderSymbols: isShowBuilderSymbols,
};
