import React from 'react';
import { LessThanTenDivider, SchemaRating } from '../../../components/specials'

export function RatingsAndLipsOrUnderarmDivider() {
  return (
    <>
      <SchemaRating />
      <LessThanTenDivider />
    </>
  );
}

