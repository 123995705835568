import { Builder } from '@builder.io/react';
import { WaxingCostPerYear } from '../../components/shared/dividers/WaxingCostPerYear';
import { BUILDER_DIVIDER_NAMES } from '../BuilderComponentsName';

Builder.registerComponent(WaxingCostPerYear, {
    name: BUILDER_DIVIDER_NAMES.WaxingCostPerYear,
    defaults: {
        bindings: {
            'component.options.globalData': 'state.siteData.globalData',
        },
    },
});
