import React, { useEffect } from 'react'
import { useMilanStores, useMilanOpenStores, useGlobalData } from '../hooks'
import { useStaticQuery, graphql } from 'gatsby'
import { createMenu } from '../components'
import { updateBorrowedStores } from '.'
import { getMilanGeoCodes } from '../utils'
import { useBuilderData } from '../hooks/useBuilderData'

export const GlobalSiteDataContext = React.createContext()

export const GlobalContextProvider = ({ children }) => {
    const localSiteData = useStaticQuery(graphql`
        query LocalSiteData {
            allSitePlugin(filter: { name: { eq: "theme-2023" } }) {
                edges {
                    node {
                        name
                        pluginOptions
                    }
                }
            }
        }
    `).allSitePlugin.edges[0].node.pluginOptions.siteData

    const milanStores = useMilanStores() // returns all milan stores from firebase
    const currentState = milanStores.find(
        state => state.name === localSiteData.state
    ) // returns the current state of this site
    // The remote currentMetro form firebase will overwrite the local one
    const currentMetro = currentState
        ? currentState.metros.find(metro => metro.name === localSiteData.name)
        : localSiteData
    const menu = createMenu(currentMetro)
    const milanOpenStores = useMilanOpenStores(milanStores) // all Milan open stores
    const globalData = useGlobalData()
    const milanGeoCodes = getMilanGeoCodes(milanStores) // returns every Milan Location's geoCode
    const { promotionalBannersTextData, isLoading: isBuilderDataLoading } =
        useBuilderData()

    const siteData = {
        menu,
        milanStores,
        milanOpenStores,
        currentMetro,
        currentState,
        globalData,
        milanGeoCodes,
        promotionalBannersTextData,
        isBuilderDataLoading,
    }

    useEffect(() => {
        if (
            siteData.currentMetro.borrowed_stores &&
            siteData.currentMetro.borrowed_stores.length !== 0 &&
            milanStores.length !== 0
        ) {
            siteData.currentMetro.borrowed_stores = updateBorrowedStores(
                siteData.currentMetro,
                milanStores
            )
        }
    }, [siteData.currentMetro, milanStores])

    return (
        <GlobalSiteDataContext.Provider value={siteData}>
            {children}
        </GlobalSiteDataContext.Provider>
    )
}

// ------------------------------------ Deprecated -------------------------------------------//
// ********* You can query siteDataJson instead of allSitePlugin ****** //
// const localSiteData = useStaticQuery(graphql`
// query LocalSiteData {
//   siteDataJson {
//     stores {
//       marketingCity
//       pathname
//       clinicName
//       address
//       addressCity
//       stateAbbrev
//       zipCode
//       phone
//       salesforceValue
//       place_id
//       open
//       platforms {
//         facebook
//         google
//         reviews {
//           list_token
//           slide_token
//         }
//         yelp
//       }
//       description {
//         line_one
//         line_two
//       }
//       with_pro_images
//       hero {
//         reversed
//         position
//       }
//       maps {
//         google
//         apple
//       }
//     }
//     state
//     pathname
//     metroPath
//     origin
//     name
//     stateAbbrev
//     grandOpening
//     google_analytics_id
//     bing_id
//   }
// }
// `).siteDataJson

// ---------------------------------------------------------------------------------------------//
// **** If you want to get the crrentMetro from Firebase ******* //

// const [currentMetro, setCurrentMetro] = useState(null)

// const getCurrentMetro = (milanStores, siteMetaData) => {
//   const currentState = milanStores.find(state => state.name === siteMetaData.state)
//   const currentMetro = currentState.metros.find(metro => metro.name === siteMetaData.metro)
//   return currentMetro
// }

// useEffect(() => {
//   if(milanStores.length) {
//     setCurrentMetro(getCurrentMetro(milanStores, data.site.siteMetadata))
//   }
// }, [milanStores])
