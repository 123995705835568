export const BUILDER_DIVIDER_NAMES = {
    UnlimitedPackage: 'UnlimitedPackage',
    UnlimitedPackage2: 'UnlimitedPackage2',
    ReasonsLHRVimeo: 'Reasons LHR Vimeo',
    AffordablePlansDivider: 'ResultsDivider',
    MilanCost: 'MilanCost',
    RatingsDivider: 'RatingsDivider',
    LipsOrUnderArmsDivider: 'LipsOrUnderArmsDivider',
    RatingsAndLipsOrUnderarmDivider: 'RatingsAndLipsOrUnderarmDivider',
    HeroWithImageAndLinkOnly: 'HeroWithImageAndLinkOnly',
    DynamicHero: 'DynamicHero',
    DynamicHeroV2: 'DynamicHeroV2',
    WhyMilanLHRIsIdeal: 'Why Milan LHR Is Ideal',
    SectionDivider: 'SectionDivider',
    ShavingAndWaxingCalc: 'ShavingAndWaxingCalc',
    LHRBenefits: 'LHR Benefits',
    LocationsBanner: 'LocationsBanner',
    WhatCustomersSay: 'WhatCustomersSay',
    CreditApproval: 'CreditApproval',
    WaxingCostPerYear: 'WaxingCostPerYear',
    HowLHRWorks: 'How LHR Works',
}
