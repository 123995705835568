import { BUILDER_DIVIDER_NAMES } from './src/builder/BuilderComponentsName';
import { Builder } from '@builder.io/react';

// Design Tokens
import { milanDesignTokens } from './src/builder/utils/builderDesignTokens';
// Dividers
import './src/builder/dividers/WaxingCostPerYear.builder';
import './src/builder/dividers/SectionDivider.builder';
import './src/builder/dividers/LipsOrUnderArmsDivider.builder';
import './src/builder/dividers/RatingsDivider.builder';
import './src/builder/dividers/LocationsBanner.builder';
import './src/builder/dividers/RatingsAndLipsOrUnderarmDivider.builder';
import './src/builder/dividers/AffordablePlansDivider.builder';

// Sections
import './src/builder/sections/UnlimitedPackage.builder';
import './src/builder/sections/LHRBenefits.builder';
import './src/builder/sections/UnlimitedPackage2.builder';
import './src/builder/sections/WhyMilanLHRIdeal.builder';
import './src/builder/sections/MilanCost.builder';
import './src/builder/sections/HowLHRWorks.builder';
import './src/builder/sections/CreditApproval.builder';

// Interactive Sections
import './src/builder/interactive-sections/ShavingWaxingCal.builder';

// Videos
import './src/builder/videos/ReasonsLHRVimeo.builder';
import './src/builder/videos/WhatOurCustomersSay.builder';

// Hero
import './src/builder/hero/HeroWithImageAndLinkOnly.builder';
import './src/builder/hero/SpecialsHero.builder';
import './src/builder/hero/SpecialsHero.builder.V2';

/**
 * Import all custom components so you can use in the builder.io editor
 * https://www.builder.io/c/docs/custom-react-components
 */

// Register a custom insert menu to add your custom components here
Builder.register('editor.settings', milanDesignTokens);

// Dividers

Builder.register('insertMenu', {
    name: 'Static Dividers',
    items: [
        { name: BUILDER_DIVIDER_NAMES.WaxingCostPerYear },
        { name: BUILDER_DIVIDER_NAMES.AffordablePlansDivider },
        { name: BUILDER_DIVIDER_NAMES.RatingsDivider },
        { name: BUILDER_DIVIDER_NAMES.LipsOrUnderArmsDivider },
        { name: BUILDER_DIVIDER_NAMES.RatingsAndLipsOrUnderarmDivider },
        { name: BUILDER_DIVIDER_NAMES.SectionDivider },
        { name: BUILDER_DIVIDER_NAMES.LocationsBanner },
    ],
});

// Sections
Builder.register('insertMenu', {
    name: 'Static Sections',
    items: [
        { item: BUILDER_DIVIDER_NAMES.LHRBenefits, name: 'LHR Benefits' },
        { name: BUILDER_DIVIDER_NAMES.UnlimitedPackage },
        { name: BUILDER_DIVIDER_NAMES.UnlimitedPackage2 },
        { name: BUILDER_DIVIDER_NAMES.MilanCost },
        { item: BUILDER_DIVIDER_NAMES.WhyMilanLHRIsIdeal, name: 'Why Milan LHR Is Ideal' },
        { item: BUILDER_DIVIDER_NAMES.HowLHRWorks, name: 'How LHR Works' },
        { name: BUILDER_DIVIDER_NAMES.CreditApproval },
    ],
});

// Interactive Sections
Builder.register('insertMenu', {
    name: 'Interactive Sections',
    items: [{ name: BUILDER_DIVIDER_NAMES.ShavingAndWaxingCalc }],
});

// Videos
Builder.register('insertMenu', {
    name: 'Videos Sections',
    items: [
        { item: BUILDER_DIVIDER_NAMES.ReasonsLHRVimeo, name: 'Reasons LHR Vimeo' },
        { name: BUILDER_DIVIDER_NAMES.WhatCustomersSay },
    ],
});

// Hero
Builder.register('insertMenu', {
    name: 'Hero Sections',
    items: [
        { name: BUILDER_DIVIDER_NAMES.DynamicHero },
        { name: BUILDER_DIVIDER_NAMES.DynamicHeroV2 },
        { name: BUILDER_DIVIDER_NAMES.HeroWithImageAndLinkOnly },
    ],
});
