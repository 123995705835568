import React from 'react';
import HeroMobile from '../../../images/builder/HairFree-Hero-Desktop-v2.webp';
import HeroTablet from '../../../images/builder/HairFree-Hero-Tablet-v2.webp';
import HeroDesktop from '../../../images/builder/HairFree-Hero-Desktop-v2.webp';
import './HeroSections.styles.css';

export function HeroWithImageAndLinkOnly() {
  return (
    <div href='https://www.milanlaser.com/locations/' className='noHover'>
      {/* // <a href="milanlaser.com/locations/" style={{textDecoration: 'none'}}> */}
      <img
        src={HeroMobile}
        alt='Hairfree Hero for Mobile'
        style={{ width: '100%' }}
        className='d-block d-sm-none'
      />
      <img
        src={HeroTablet}
        alt='Hairfree Hero for Tablet'
        style={{ width: '100%' }}
        className='d-none d-sm-block d-lg-none'
      />
      <img
        src={HeroDesktop}
        alt='Hairfree Hero for Desktop'
        style={{ width: '100%' }}
        className='d-none d-lg-block'
      />
    </div>
  );
}

export default HeroWithImageAndLinkOnly;
