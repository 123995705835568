import { Builder } from '@builder.io/react';
import { BUILDER_DIVIDER_NAMES } from '../BuilderComponentsName';
import MilanCreditBuilder from '../components/sections/MilanCreditBuilder';

Builder.registerComponent(MilanCreditBuilder, {
    name: BUILDER_DIVIDER_NAMES.MilanCost,
    defaults: {
        bindings: {
            'component.options.globalData': 'state.siteData.globalData',
        },
    },
});
