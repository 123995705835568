import React from 'react'
import { StructuredData } from '../..'

export function FaqsStructuredData() {
    return (
        <StructuredData>
            {{
                '@context': 'https://schema.org',
                '@type': 'FAQPage',
                name: 'Laser Hair Removal FAQs',
                mainEntity: [
                    {
                        '@type': 'Question',
                        name: 'Is laser hair removal safe?',
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: '<p>Your safety is a top priority at Milan! We use the safest laser in the industry, and take special care to prepare you for laser hair removal with our Pre and Post care guide.</p><p>Additionally, all of our treatment providers are <strong>Authorized Candela Practitioners</strong> who have been extensively trained by Independent Candela Trainers and are supervised by medical doctors. Their comprehensive training touches on everything from laser physics to medical conditions affecting hair growth, so you can rest assured that you’re in good hands with us!</p>',
                        },
                    },
                    {
                        '@type': 'Question',
                        name: 'Does laser hair removal really work?',
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: 'Yes! There are so many ways to temporarily remove hair, but for permanent results, laser hair removal (not IPL!) is the safest way to make unwanted body hair a thing of the past!',
                        },
                    },
                    {
                        '@type': 'Question',
                        name: 'How permanent is laser hair removal?',
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: 'Hair is gone! Most of our clients are up to 95% hair-free in treated areas within 7 to 10 treatments. Hormones, pregnancy and the odd stubborn hair that just won’t quit, is why we provide our Unlimited Package — so you never pay touch-up fees or need to buy additional sessions.',
                        },
                    },
                    {
                        '@type': 'Question',
                        name: 'How effective are at-home laser hair removal devices?',
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: '<p>At-home devices use mostly IPL technology which is not as effective as our lasers. These devices are much smaller than our lasers & don’t offer the cooling mist to ease your discomfort. Compared to our treatments, at-home devices are more time consuming, more painful and produce inferior results. Don’t be fooled by cheap products that take up your time without producing real effects.</p><p>A recent study of an FDA-approved Silk’n laser hair removal home device was published by Lasers in Surgery and Medicine, a leading scientific laser journal. The people in the study had 4 to 6 treatments each. 4 weeks after the last treatment they showed an average of 36% hair reduction. However, 12 weeks later the hair had mostly returned.</p>',
                        },
                    },
                    {
                        '@type': 'Question',
                        name: 'Does laser hair removal work for blonde or red hairs?',
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: '<p>Our lasers usually do, but the best way to determine if it will work for you is to come in for a free consultation. If your hair is too light to work, we will let you know. Our #1 priority is customer satisfaction, so we absolutely will not sell you laser hair removal if it won’t produce absolutely stellar results!</p><p><strong>Blondes:</strong> Laser hair removal will work on most people with blonde hair as long as the hair isn’t “bleach blonde” or “platinum” in color.</p><p><strong>Reds:</strong> It will frequently work on red hair as long as there is some pigment for the laser to capture.</p><p><strong>Gray:</strong> Gray hair is, well, a gray area! We would have to see your hair to determine exactly how much pigment is left for the laser to target. This can be determined during your consultation!</p>',
                        },
                    },
                    {
                        '@type': 'Question',
                        name: 'Is laser hair removal safe & effective on dark skin?',
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: 'At Milan — Yes! Our laser hair removal works on all skin tones. Some hair removal methods are limited to light skin tones. But our laser is precise enough to target the hair follicle, and eliminate hair at the root without damaging your skin. Even the darkest of skin tones can be treated.',
                        },
                    },
                    {
                        '@type': 'Question',
                        name: 'What body hair can I have removed with a laser?',
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: 'We can help you permanently get rid of unwanted hair just about anywhere on your body. Whether it’s your underarms, lip/chin, back, chest, bikini area — basically anywhere except the scalp and right around your eyes — we can help you get the smooth skin you’ve always wanted!',
                        },
                    },
                    {
                        '@type': 'Question',
                        name: 'Why laser hair removal over waxing?',
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: '<p>Laser hair removal treatments yield permanent results while waxing, shaving, or depilatories will only give you temporary results. Waxing is significantly more painful than laser hair removal, and while laser feels like a rubber-band snap we’ve never had a client leave because of how a treatment feels.</p><p>Once you’ve completed your laser hair removal treatments, you’re done forever. Unlike other hair removal solutions, you won’t have to come back monthly, for years on end. You’ll save thousands of dollars and hundreds of hours in time.</p>',
                        },
                    },
                    {
                        '@type': 'Question',
                        name: 'How long does a treatment take?',
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: 'Treatments are so quick you could do them during lunch! A lip or underarm treatment can be done in less than 10 minutes!',
                        },
                    },
                    {
                        '@type': 'Question',
                        name: 'How many treatments will I need?',
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: '<p>With our Unlimited Package (included with every area), most people need between 7 to 10 treatments to see up to 95% hair reduction. The best part of this package – besides never shaving again – is that no matter how many treatments you need, it’s the same price!</p><p>At Milan, you’ll never worry about buying additional treatments or paying touch-up fees. Unlike other places, once you’ve purchased an area with us it’s covered for the rest of your life.</p><p>Each treatment is completed 5 weeks apart.</p>',
                        },
                    },
                    {
                        '@type': 'Question',
                        name: 'Can I get laser hair removal if I have a tattoo?',
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: '<p>Yes! If you have a tattoo, you can still get laser hair removal but not directly over the tattoo—the same goes with removed tattoos. This is because, during treatment, the laser targets the pigment in your hair follicle to safely and effectively eliminate your hair at the root. However, the laser can’t tell the difference between ink pigment and hair follicle pigment. So if treated over, the ink pigment could scatter and result in a burn as it tries to escape the skin.</p><p>If you’re thinking about getting a tattoo, then laser hair removal is a great option to get beforehand. You can get a clean slate, so to speak, over the area you want tattooed.</p><p>It’s very important to disclose tattoos and removed tattoos before beginning treatments.</p>',
                        },
                    },
                    {
                        '@type': 'Question',
                        name: 'What are the risks of removing hair by laser?',
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: 'Our lasers are incredibly safe, and you’re treated by highly trained medical professionals. We provide eye protection and take all necessary precautions so your treatments are effective, yet safe. Laser hair removal is a simple procedure and there’s almost no recovery time so you can return to work, apply makeup or be active immediately after your treatment. We only ask that you wear sunscreen to protect the treated area.',
                        },
                    },
                    {
                        '@type': 'Question',
                        name: 'How should I prepare for laser hair removal?',
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: '<ul><li>You can shave! In fact, we want you to shave the day before or day-of your treatment.</li><li>Your skin needs to be it’s natural skin tone (no sun, no tanning beds, no self-tanner).</li><li>Come in for your treatment with clean skin (no lotions or makeup).</li></ul>',
                        },
                    },
                    {
                        '@type': 'Question',
                        name: 'What does laser body hair removal feel like?',
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: 'We’ve never had anyone leave because of how a laser pulse feels on the body. Most clients describe the sensation similar to the feeling of being snapped with a rubber band. Compared to waxing the treatment has very little discomfort. Our state of the art laser uses a cooling technology to safely cool the skin in the milliseconds prior to, as well as after, each laser pulse, which decreases discomfort as well.',
                        },
                    },
                    {
                        '@type': 'Question',
                        name: 'How much does laser hair removal cost?',
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: `The average person who shaves their legs and underarms spends upwards of $10,000 over a lifetime on razors, shaving cream, exfoliants, and more. If you wax your bikini area, you spend over $18,000 on a lifetime of salon appointments. These costs are only for temporary results, while laser hair removal gives you permanent results! Milan Laser offers monthly specials and affordable laser hair removal payment plans with 0% interest available to fit anyone’s monthly budget, plus unlimited treatments for life at no additional costs with their exclusive Unlimited Package™.`,
                        },
                    },
                    {
                        '@type': 'Question',
                        name: 'How do I know if I qualify for a payment plan?',
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: "Don’t worry, everyone is approved for at least one of our payment plans! You can even find out which payment options you pre-qualify for before coming in for a free consultation. Filling out the <a href'https://www.milanlaser.com/finance/'>prequalification application</a> does not affect your credit score, takes less than 2 minutes, and provides you with private results right away!",
                        },
                    },
                    {
                        '@type': 'Question',
                        name: 'Can I get laser hair removal and maintain my personal or religious modesty standards?',
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: '<p>At Milan, we do everything that we can to make you as comfortable as possible while helping you say goodbye to unwanted hair forever. We know that everyone has their modesty standards—whether they are religiously based or simply a personal choice—and we don’t want you to feel like you have to choose between being modest and being hairy.</p><p>That’s why we do our best to maintain your modesty throughout your laser hair removal journey. Here’s how we protect your modesty:</p><ul><li><strong>Private Consultations.</strong> During your consultation, you’ll have the opportunity to have all of your laser hair removal questions answered in a one-on-one setting with a laser hair removal expert.</li><li><strong>Female Providers.</strong> We have highly-trained female medical professionals at every location.</li><li><strong>Staying Covered.</strong> For many body areas, you can stay fully clothed during the treatment and only have the area being treated showing. Areas like the upper lip, chin, underarms, lower legs, and arms, can all be done without needing to undress or reveal any other parts of your body.</li><li><strong>Treatment Robes.</strong> If you need to remove any clothing to have your treatment completed, we provide robes for you to wear to help keep the rest of your body covered. While you are changing into the robe in the treatment room, no one else is in the room, and the treatment provider will knock to request permission to enter once you have put the robe on and are on the treatment table.</li></ul>',
                        },
                    },
                    {
                        '@type': 'Question',
                        name: 'What laser does Milan Laser use?',
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: '<p>We use the Candela GentleMax Pro for all of our treatments! This dual-wavelength laser combines Alexandrite and Nd: YAG laser technology into one machine, making it easy and safe to treat all skin tones. Additionally, the built-in cooling technology protects the skin and makes treatments less painful than waxing.</p>',
                        },
                    },
                    {
                        '@type': 'Question',
                        name: 'Where can I find Laser Hair Removal near me?',
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: "We have more than 100 convenient Locations across the country – making us the best solution to getting rid of your unwanted hair. <a href='https://milanlaser.com/locations/'>Locate the Milan closest to you</a> and take the first step toward never shaving again!",
                        },
                    },
                ],
            }}
        </StructuredData>
    )
}
export default FaqsStructuredData
