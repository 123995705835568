import React from 'react';
import { Builder } from '@builder.io/react';
import { BUILDER_DIVIDER_NAMES } from '../BuilderComponentsName';
import { LPCredit } from '../../components/lp/LpCredit';

const LPCreditWrapper = (props) => {
    return (
        <div id="lp-page">
            <LPCredit {...props} />
        </div>
    );
};

Builder.registerComponent(LPCreditWrapper, {
    name: BUILDER_DIVIDER_NAMES.CreditApproval,
    defaults: {
        bindings: {
            'component.options.globalData': 'state.siteData.globalData',
        },
    },
});
